














































































































import Vue from "vue";
import { mapGetters } from "vuex";
import { VIEW_JOB_STATE } from "@/store/modules/common/constants";
import { ViewJobState } from "@/store/modules/common/interfaces";
import { get_color_by_name } from "@/utils/colors";

export default Vue.extend({
  name: "JobTitleBlock",
  data() {
    return {
      title_copy_snackbar: false,
      edit_details_allowed_states: [
        // ViewJobState.JOB_LISTING,
        ViewJobState.STANDARDISED_JOB_POST,
        ViewJobState.LINKEDIN_JOB_POST,
        ViewJobState.TEXT_JOB_POST,
        ViewJobState.EDIT_TEXT_JOB_POST,
        ViewJobState.FILE_JOB_POST
      ],
      copy_details_allowed_states: [
        // ViewJobState.STANDARDISED,
        // ViewJobState.STANDARDISED_JOB_POST
      ]
    };
  },
  props: {
    title: {
      type: String,
      required: true
    },
    employment_type: {
      required: true
    }
  },
  computed: {
    ViewJobState() {
      return ViewJobState;
    },
    ...mapGetters("common", {
      view_job_state: VIEW_JOB_STATE
    })
  },
  methods: {
    transform_edit_icon(icon: HTMLElement): HTMLElement {
      const color = get_color_by_name("primary");
      for (let node of icon.children) node.setAttribute("stroke", color);
      return icon;
    },
    transform_copy_icon(icon: HTMLElement): HTMLElement {
      const color = get_color_by_name("white");
      for (let node of icon.children) node.setAttribute("stroke", color);
      return icon;
    },
    async copy_title() {
      await navigator.clipboard.writeText(this.title);
      this.title_copy_snackbar = true;
    }
  }
});
