var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page",attrs:{"id":"view_job_details"}},[_c('NavSideBar',{attrs:{"module":"recruiter"}}),_c('v-main',{staticClass:"content"},[_c('div',{staticClass:"header d-flex align-center justify-space-between"},[(
          _vm.view_job_state === _vm.ViewJobState.JOB_LISTING ||
          _vm.view_job_state === _vm.ViewJobState.TEXT_JOB_POST ||
          _vm.view_job_state === _vm.ViewJobState.EDIT_TEXT_JOB_POST ||
          _vm.view_job_state === _vm.ViewJobState.DEFAULT ||
          _vm.view_job_state === _vm.ViewJobState.FILE_JOB_POST
        )?_c('p',{staticClass:"mb-0 base-section-title"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.view-job.title"))+" ")]):(_vm.view_job_state === _vm.ViewJobState.LINKEDIN_JOB_POST)?_c('p',{staticClass:"mb-0 base-section-title"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.view-job.linkedin-title"))+" ")]):(_vm.view_job_state === _vm.ViewJobState.STANDARDISED_JOB_POST)?_c('p',{staticClass:"mb-0 base-section-title"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.view-job.standardised-title"))+" ")]):_vm._e(),(_vm.view_job_state !== _vm.ViewJobState.DEFAULT)?_c('v-btn',{staticClass:"base-btn base-body-text",attrs:{"text":""},on:{"click":_vm.go_back_pressed}},[_c('inline-svg',{staticClass:"mr-2",attrs:{"src":require('@/assets/icons/linear/arrow-left-1.svg')}}),_vm._v(" "+_vm._s(_vm.$t("shared.go-back"))+" ")],1):_vm._e()],1),(!_vm.loading)?[(!_vm.edit_job_details)?_c('section',{staticClass:"my-6 job-details d-flex flex-column"},[_c('JobTitleBlock',{attrs:{"title":_vm.job.job_title,"employment_type":null},on:{"edit_posted_job":_vm.edit_posted_job}}),_c('v-row',{staticClass:"extra-details"},[_c('v-col',[_c('div',{staticClass:"base-card extra-details-wrapper"},[_c('div',{staticClass:"details",class:{
                  'flex-grow-1':
                    !_vm.view_on_linkedin_allowed_states.includes(_vm.view_job_state)
                }},[(_vm.industries_allowed_states.includes(_vm.view_job_state))?_c('ExtraDetails',{attrs:{"title":_vm.$t('recruiter.view-job.industry-title'),"color":"#ADE3E3","icon":_vm.industry_icon,"value":_vm.job.sector}}):_vm._e(),(_vm.track_allowed_states.includes(_vm.view_job_state))?_c('ExtraDetails',{attrs:{"title":_vm.$t('recruiter.view-job.track-title'),"color":"#FF8473","icon":_vm.track_icon,"value":"Software and Application"}}):_vm._e(),(_vm.experience_allowed_states.includes(_vm.view_job_state))?_c('ExtraDetails',{attrs:{"title":_vm.$t('recruiter.view-job.experience-title'),"color":"#3CE17E","icon":_vm.experience_icon,"value":_vm.job.experience
                      ? _vm.job.experience
                      : _vm.$t('recruiter.no-experience')}}):_vm._e()],1),(
                  _vm.view_on_linkedin_allowed_states.includes(_vm.view_job_state)
                )?_c('v-btn',{attrs:{"text":"","x-large":"","color":"primary","disabled":!_vm.job.url,"href":_vm.job.url,"target":"_blank"}},[_c('inline-svg',{staticClass:"mr-2",attrs:{"src":require('@/assets/logos/linkedin-3.svg')}}),_c('p',{staticClass:"text-capitalize mb-n1"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.view-job.linkedin-view"))+" ")])],1):_vm._e()],1)])],1),_c('v-row',[_c('v-col',[_c('JobDescriptionCard',{attrs:{"description":_vm.job.job_description}})],1)],1),_c('v-row',[_c('v-col',[_c('SkillsWithGroups',{attrs:{"skills":_vm.job.skills_list,"title":_vm.$t('recruiter.view-job.required-skills')}})],1)],1),(
            _vm.critical_work_functions_allowed_states.includes(_vm.view_job_state)
          )?_c('v-row',[_c('v-col',[_c('CriticalWorkFunctions')],1)],1):_vm._e(),(_vm.other_specifications_allowed_states.includes(_vm.view_job_state))?_c('v-row',[_c('v-col',[_c('OtherSpecifications')],1)],1):_vm._e(),(_vm.matching_candidates_allowed_states.includes(_vm.view_job_state))?[_c('v-row',[_c('v-col',[_c('p',{staticClass:"mb-8 mb-md-10 ms-2 base-section-title"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.dashboard.matching-candidates-title"))+" ")]),_c('v-row',[(_vm.job.candidates_loading)?_vm._l((4),function(index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-skeleton-loader',{attrs:{"type":"card"}})],1)}):(
                    !_vm.job.candidates_loading &&
                    _vm.job.matching_candidates.length <= 0
                  )?[_c('v-col',{staticClass:"base-card"},[_c('DataNotFound')],1)]:_vm._l((_vm.job.matching_candidates),function(candidate,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('CandidateEmployeeCard',{attrs:{"data":candidate,"match_title":_vm.$t('recruiter.view-job.skills-match'),"match_value":_vm.format_overall_score(candidate.score) + '%',"unique_color":true}})],1)})],2)],1)],1)]:_vm._e(),(
            _vm.recommended_job_profile_allowed_states.includes(_vm.view_job_state) &&
            !_vm.previous_job
          )?[(_vm.get_recommend_job_profiles)?_c('v-row',[_c('v-col',[_c('p',{staticClass:"mb-8 mb-md-10 ms-2 base-section-title"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.view-job.recommended-job-profiles"))+" ")]),_c('v-row',_vm._l((_vm.get_recommend_job_profiles),function(job,index){return _c('JobCard',{key:index,attrs:{"module":"recruiter","show_matching_score":false,"job":job},on:{"job_pressed":_vm.job_pressed}})}),1)],1)],1):_vm._e()]:_vm._e(),(_vm.post_job_button_allowed_states.includes(_vm.view_job_state))?[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"base-submit-button",attrs:{"loading":_vm.recruiter_loading},on:{"click":_vm.post_new_job}},[_vm._v(" "+_vm._s(_vm.$t("recruiter.post-job.post-job-btn"))+" ")])],1)],1)]:_vm._e()],2):(_vm.edit_job_details)?[_c('div',[_c('v-dialog',{attrs:{"persistent":"","width":_vm.$vuetify.breakpoint.mdAndDown ? '100%' : '1100px'},model:{value:(_vm.edit_job_details),callback:function ($$v) {_vm.edit_job_details=$$v},expression:"edit_job_details"}},[[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-toolbar',{staticClass:"custom-toolbar",attrs:{"fade":""}},[_c('v-toolbar-title',{staticClass:"base-section-title"},[_vm._v("Edit Job Detail")]),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.edit_job_details = false}}},[_vm._v(" mdi-close ")])],1),_c('v-card-text',[_c('EditJobDetails',{attrs:{"job":_vm.job},on:{"update_job":_vm.update_job}})],1)],1)]],2)],1)]:_vm._e()]:[_c('RootLoader')]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }